import { Component, HostListener } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { GeneralService } from "src/app/commons/services/general.service";
import { SeoService } from "src/app/core/services/seo.service";
import { ThemeService } from "src/app/core/services/theme.service";
import { ApiService, SiteConfigService } from "src/app/core/services";
import { Subject } from "rxjs";

@Component({
  selector: "sv-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [GeneralService],
})
export class AppComponent {
  private unsubscribe$ = new Subject<void>();

  // defaults
  title = "";
  collapseState: boolean = false;
  isTreeVisible: boolean = false;
  isCartPanelVisible: boolean = false;
  isModalOpen: boolean = false;
  alternateHrefLang = [
    {
      hreflang: "fi",
      href: "http://fi.littelitaly.fi/samplepage.html",
    },
    {
      hreflang: "sv",
      href: "http://www.littelitaly.se/samplepage.html",
    },
  ];

  constructor(
    public translate: TranslateService,
    private generalService: GeneralService,
    private seoService: SeoService,
    private themeService: ThemeService,
    private siteConfigService: SiteConfigService,
    private apiService: ApiService
  ) {
    // this.themeService.setVariable("--primary", "cyan");
    // this.themeService.setVariable("--secondary", "purple");
    const { name } = this.siteConfigService.siteConfig;
    this.themeService.setTheme();
    this.siteConfigService.checkSiteVersion();
    let document = this.generalService.getDocument;
    document
      .getElementById("appFavicon")
      .setAttribute("href", this.siteConfigService.siteConfig.favicon);

    this.title = name;

    // this.seoService.setTitle = name || "Shop";
    this.seoService.setDefaultHreflang = null;
    // this.seoService.setAlternateHreflang = this.alternateHrefLang;
    this.seoService.setCanonicalURL = null;
    // this.seoService.setMetaTags = [
    //   { name: "description", content: "storevese app for groceries" },
    //   { name: "keywords", content: "groceries food buy" },
    //   { name: "robots", content: "index, follow" },
    //   { name: "author", content: "storeverse" },
    //   { name: "viewport", content: "width=device-width, initial-scale=1" },
    //   { charset: "UTF-8" },
    // ];

    const tenant = this.siteConfigService.siteConfig.tenant;
    let metaObj = require(`src/app/tenants/${tenant}/seo/meta.json`);

    this.seoService.setTitle = metaObj.siteTitle;
    this.seoService.setMetaTags = [
      { name: "description", content: metaObj.siteDescription },
      { name: "keywords", content: metaObj.siteTitle },
      { name: "robots", content: "index, follow" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { charset: "UTF-8" },
    ];

    translate.addLangs(["en", "fi"]);
    translate.setDefaultLang("fi");
    let browserLang = translate.getBrowserLang();

    if (!browserLang || !browserLang.match(/en|fi/)) {
      browserLang = "fi";
    }

    if (this.siteConfigService.siteConfig.defaultLanguage) {
      translate.use(this.siteConfigService.siteConfig.defaultLanguage);
    } else {
      translate.use(browserLang);
    }

    // ***************** CHANGE LANGUAGE HERE *********************
    // translate.use("en");
    // translate.use("fi");
    // translate.use(this.siteConfigService.siteConfig.defaultLanguage);
    // translate.use(browserLang);
    // ***************** CHANGE LANGUAGE HERE *********************

    // **** Do Not Remove ****
    // **** WIP - Work In Progress (Translations / localization) ****

    // // extract translation as per language
    // const tObs = translate.getTranslation(browserLang);
    // tObs.subscribe(translation => {
    //   const translationByLang = reduce(
    //     translation,
    //     (result, obj, key) => {
    //       result[key] = obj[browserLang] ? obj[browserLang] : obj;
    //       return result;
    //     },
    //     {}
    //   );
    //   translate.setTranslation(browserLang, translationByLang);
    // });
    //**************************/

    this.generalService.observeProductTreeState.subscribe((isVisible) => {
      this.isTreeVisible = isVisible;
    });
    this.generalService.observeCartPanelState.subscribe((isVisible) => {
      this.isCartPanelVisible = isVisible;
    });
    this.generalService.observeModalState.subscribe((modal) => {
      if (modal) {
        this.isModalOpen = modal.isOpen;
      }
    });

    // hit pages service here...
    this.getHeaderPages();
  }

  async getHeaderPages() {
    try {
      const { status_code, result } = await this.apiService
        .get("/wp/pages/headers-shop-front")
        .toPromise();

      if (status_code && status_code === 200 && result) {
        this.generalService.headerPages.next(result);
      }
    } catch (error) {
      throw error;
    }
  }
  //

  // async getRecipePages() {
  //   try {
  //     const { status_code, result } = await this.apiService
  //       .get("/wp/recipes?page=1")
  //       .toPromise();

  //     if (status_code && status_code === 200 && result) {
  //       console.log("RESULT getRecipePages >>> ", result)
  //     }
  //   }
  //   catch (error) {
  //     throw error;
  //   }
  // }

  // async getRecipePage(pageId) {
  //   try {
  //     const { status_code, result } = await this.apiService
  //       .get("/wp/recipe/"+pageId)
  //       .toPromise();

  //     if (status_code && status_code === 200 && result) {
  //       console.log("RESULT pageId >>> ", result)
  //     }
  //   }
  //   catch (error) {
  //     throw error;
  //   }
  // }

  collapseMenu() {
    this.collapseState = !this.collapseState;
  }

  @HostListener("window:scroll", ["$event"])
  scrollMe(e) {
    const scrollHeight = e.target.documentElement.scrollTop;

    this.generalService.scrollHeight = scrollHeight;

    if (scrollHeight > 5) {
      this.generalService.setNavbarHeight = 0;
      return;
    }
    this.generalService.setNavbarHeight =
      this.generalService.getCartPanelTopHeight;
  }
}
