export const environment = {
  production: false,
  svAPIKey: "KHL321",
  baseUrl: "https://staging.public.api.lafka.dev/api/v1",
  googleAppId:
    "734567008925-nvbbrlecot0d1dip8pb7f16anu34i2mp.apps.googleusercontent.com",
  facebookAppId: "1021525404867816",
  firebase: {
    apiKey: "AIzaSyArzPuVsKaUQ27sG0BAsQPWvD2IXZIybyo",
    authDomain: "storeversekh24-staging-43760.firebaseapp.com",
    projectId: "storeversekh24-staging-43760",
    storageBucket: "storeversekh24-staging-43760.appspot.com",
    messagingSenderId: "423810244282",
    appId: "1:423810244282:web:05e44b4355dd1a7b1eec83",
    measurementId: "G-JR92PXDL65",
  },
};
